.template-questions-form {
  .wrapper-alert-inline {
    margin-top: 0;
  }
  
  .multiple-input-fields {
    button.dash,
    button.plus {
      width: 36px;
      height: 36px;
      padding: 0;
      margin: 0;
      > span {
        width: 34px;
        height: 34px;
        display: block;
        padding: 0;
        margin: 0;
        &::before {
          top: 50%;
          left: 50%;
          margin: -8px 0 0 -8px;
        }
      }
    }

    label {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 0 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 115%;
      color: rgba($black, 0.65);
    }

    .btn-flex-group {
      margin-top: 0;
      margin-bottom: 16px;

      display: flex;
      align-items: flex-start;
      width: 100%;

      > div {
        width: 100%;

        > .wrapper-input:nth-child(2) {
          margin-bottom: 0;
        }

        textarea {
          min-height: 76px;
        }
      }

      .button-wrapper {
        padding: 0;
        width: 36px;

        button {
          min-width: 36px;
        }
      }
    }
  }
}
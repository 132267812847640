@import "../../assets/scss/variables.scss";

.closePanel-env {
  width: 480px;
  background: $white;
  box-shadow: 0px 6px 24px -6px rgba($black, 0.2);
  border-radius: 4px;
  padding: 24px;
  
  .closePanel-env__header {
    position: relative;
    .closePanel-env__title {
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: $black;
      max-width: calc(100% - 24px);
      overflow: hidden;
    }
    .close {
      position: absolute;
      width: 15px;
      height: 15px;
      outline: none;
      background: none;
      cursor: pointer;
      border: 0 none;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      svg {
        display: block;
        width: 14px;
        height: 14px;
        margin: 0 auto;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  }
  .closePanel-env__section {
    margin: 16px 0;
    p {
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }
  }

  .closePanel-env__action-btn {
    margin: 16px -4px;
    width: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      padding: 0 4px;
    } 
  }
}

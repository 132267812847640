@import "./variables.scss";

.form-container {
  span.error {
    color: $red-600;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    width: calc(100% - 16px);
    margin: 4px 9px 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .form-container__actions {
    padding: 2px;
  }

  .wrapper-textarea {
    background: $white;
    margin: 16px 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    display: block;
    border: 0 none;
    border-radius: 4px 4px 0 0;
    position: relative;
    min-height: 87px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      background: $form-bg-disabled;
    }

    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: stretch;
      width: 200%;

      textarea {
        width: 50%;
        min-height: 60px;
        padding: 26px 16px 6px;
        margin: 0;
        resize: none;
        background: none;
        appearance: none;
        border: none;
        min-height: 87px;

        position: relative;
        z-index: 2;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $form-text-color;
        outline: none;
        background: transparent;

        border-bottom: 1px solid $form-border-color;

        order: 0;
        flex: 1 1 auto;
        align-self: auto;

        &::placeholder {
          color: transparent;
        }

        &:focus {
          border-bottom-color: $form-border-color-active;
          box-shadow: inset 0px -1px 0px $form-border-color-active;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
      }

      textarea {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
      }

      textarea::-webkit-scrollbar {
        display: none;
      }

      label {
        display: block;
        width: calc(100% - 32px);
        height: 18px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $form-label-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        top: 6px;
        left: 16px;
        margin: 0;
        padding: 0;
        z-index: 1;
      }

      &::after {
        order: 0;
        flex: 1 1 auto;
        align-self: auto;
        display: block;
        overflow: hidden;
        width: 50%;
        min-height: 87px;

        margin: 0;
        padding: 26px 16px 6px;
        display: block;

        content: attr(data-value) " ";

        white-space: pre-wrap;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $white;

        border-bottom: 1px solid $white;
      }

      textarea.empty + label {
        top: 14px;
      }

      textarea:focus.empty {
        &::placeholder {
          color: rgba($form-text-color, 0.5);
        }
        & + label {
          top: 6px;
        }
      }
    }

    &.error {
      margin-bottom: 0;
      > div {
        textarea {
          border-bottom-color: $red-600;
          &:focus {
            border-bottom-color: $red-600;
            box-shadow: inset 0px -1px 0px $red-600;
          }
        }
      }
    }
  }

  .wrapper-input {
    background: $white;
    margin: 16px 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    display: block;
    border: 0 none;
    border-radius: 4px 4px 0 0;
    position: relative;
    height: 52px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      background: $form-bg-disabled;
    }

    input {
      width: 100%;
      height: 52px;
      padding: 26px 16px 6px;
      margin: 0;
      resize: none;
      background: none;
      appearance: none;
      border: none;

      position: relative;
      z-index: 2;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $form-text-color;
      outline: none;
      background: transparent;

      border-bottom: 1px solid $form-border-color;

      &::placeholder {
        color: transparent;
      }

      &:focus {
        border-bottom-color: $form-border-color-active;
        box-shadow: inset 0px -1px 0px $form-border-color-active;
      }
    }

    label {
      display: block;
      width: calc(100% - 32px);
      height: 18px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $form-label-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: absolute;
      top: 6px;
      left: 16px;
      margin: 0;
      padding: 0;
      z-index: 1;
    }

    input.empty + label {
      top: 14px;
    }

    input:focus.empty {
      &::placeholder {
        color: rgba($form-text-color, 0.5);
      }
      & + label {
        top: 6px;
      }
    }

    &.error {
      margin-bottom: 0;
      input {
        border-bottom-color: $red-600;
        &:focus {
          border-bottom-color: $red-600;
          box-shadow: inset 0px -1px 0px $red-600;
        }
      }
    }
  }

  .wrapper-file-upload {
    .file {
      background: $white;
      margin: 16px 0 0;
      padding: 0;
      width: 100%;
      overflow: hidden;
      display: block;
      border: 0 none;
      border-radius: 4px 4px 0 0;
      position: relative;
      height: 52px;
      border-bottom: 1px solid $form-border-color;
      input {
        opacity: 0;
        width: 100%;
        height: 100%;

        padding: 26px 48px 6px 16px;
        margin: 0;
        resize: none;
        background: none;
        appearance: none;
        border: none;

        z-index: 2;
        position: relative;
        cursor: pointer;
      }

      label {
        display: block;
        width: calc(100% - 48px);
        height: 18px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $form-label-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        top: 6px;
        left: 16px;
        margin: 0;
        padding: 0;
        z-index: 1;
      }

      .file-custom-name {
        display: block;
        width: calc(100% - 48px);
        height: 20px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        top: 24px;
        left: 16px;
        margin: 0;
        padding: 0;
        z-index: 1;
      }

      .button {
        position: absolute;
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0;
        top: 12px;
        right: 16px;
        & > span {
          display: inline-block;
          // width: 4px;
          // height: 4px;
          background: $black;
          margin: 2px;
          border: 2px solid $black;
          border-radius: 50%;
          margin: 0 3px;
          & + span {
            margin-left: 0;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.disabled {
        background: $form-bg-disabled;
      }

      input.empty + label {
        top: 14px;
      }

      // input:focus.empty {
      //   &::placeholder {
      //     color: rgba($form-text-color, 0.5);
      //   }
      //   & + label {
      //     top: 6px;
      //   }
      // }

      &.error {
        margin-bottom: 0;
        border-bottom-color: $red-600;
      }
    }
    .form-element-description {
      color: $form-label-color;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      width: calc(100% - 16px);
      margin: 4px 9px 16px;
      & > p {
        margin: 0;
      }
    }
  }
}

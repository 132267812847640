.wrapper.wrapper-page {
  &.settings-page {
    h1 + .tab {
      margin-top: 16px;
    }
    
    .settings-form {
      margin: 16px 0 0;
      position: relative;
      max-width: 600px;
      min-height: 120px;
      &:first-child {
        margin: 0;
      }

      .wrapper-alert-inline {
        max-width: 600px;
        margin: 0;
      }

      .wrapper-alert-inline:empty + div {
        margin-top: 0;
      }
    }
  }
}

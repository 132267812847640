* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
figure {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

small {
  font-size: 80%;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

address {
  font-style: normal;
}

button,
input,
optgroup,
textarea {
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  appearance: none;
}

textarea {
  overflow: auto;
  resize: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

td,
th {
  padding: 0;
}

p {
  margin: 0;
}
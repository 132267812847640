@import "../../assets/scss/variables.scss";

.tab {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  .tab__btn {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }
  .tab__panel {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
  }

  .tab__btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    box-shadow: inset 0px -1px 0px $gray-200;

    > div {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      width: auto;
      min-width: 74px;

      button {
        display: block;
        width: 100%;
        margin: 0;
        outline: none;
        cursor: pointer;
        height: 36px;
        border: 0 none;
        padding: 8px 16px;
        background: none;
        color: $gray-600;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        > span {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:focus-visible,
        &:hover {
          color: $black;
          background: $gray-100;
          box-shadow: inset 0px -2px 0px $primary;
        }

        &:disabled,
        &[disabled] {
          color: $gray-600 !important;
          cursor: not-allowed !important;
          background: none !important;
          box-shadow: none !important;
          opacity: 0.5 !important;
          >span {
            padding: 0 !important;
            &::after {
              content: none !important;
              display: none !important;
            }
          }
        }

      }

      &.active {
        button {
          background: $white;
          box-shadow: inset 0px -2px 0px $primary;
          cursor: default;
          color: $black;
        }
      }

      &:not(.active).error {
        button {
          >span {
            display: inline-block;
            vertical-align: top;
            width: auto;
            min-height: 20px;
            max-width: 100%;
            position: relative;
            padding: 0 17px 0 0;
      
            &::after {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              position: absolute;
              top: 50%;
              right: 0;
              margin: -5px 0 0;
              padding: 0;
              background: $red-600;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }

  .tab__panel {
    width: 100%;
    padding: 16px 0 2px;
  }
}

.flex-from-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  
  > div:nth-child(1) {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    height: calc(100% - 68px);
    max-height: calc(100% - 68px);
    width: 100%;
  }

  > div:nth-child(2) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 100%;
    height: 68px;
    padding: 16px 0;
  }
}

.wrapper-alert-inline {
  margin: 16px 0;
  max-height: 96px;
  overflow: auto;
  position: relative;
  .alert-inline {
    margin: 0;
    overflow: auto;
    position: static;
    max-height: none;
    p ~ p.bold {
      display: none;
    }
  }
}

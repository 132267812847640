.wrapper.wrapper-page {
    &.session-chat {
      .breadcrumbs + .wrapper-alert-inline {
        margin-top: 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .api-icon {
        &.user {
          &::before {
            background: transparent url("../../assets/svg/publisher/user.svg") no-repeat scroll 0 0;
          }
        }
        &.ai {
          &::before {
            background: transparent url("../../assets/svg/publisher/ai.svg") no-repeat scroll 0 0;
            background-size: 16px 16px;
          }
        }
      }
    }
  }
  
@import './variables.scss';

.menu-sidebar {
	width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 16px 0;
  }

	&.expanded {
		width: 200px;

		li {
			a {
				font-size: 14px;
			}
		}
	}

	.menu-sidebar-list {
		margin: 0;
		width: 100%;
		max-width: 200px;
		padding: 0;
		list-style: none;
    overflow: auto;
    background-color: $gray-100;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
		li {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
			display: block;
      width: calc(100% - 16px);
      max-width: 184px;
      margin: 4px 8px;
      overflow: hidden;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
			a {
				display: block;
				transition: text-indent $trs ease, font-size $trs ease;
				will-change: text-indent, font-size;
				color: $gray-600;
				align-items: center;
				width: 100%;
        padding: 8px 8px 8px 40px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        position: relative;
        text-decoration: none;
        > span {
          display: block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:nth-child(1) {
            width: 24px;
            height: 24px;
            overflow: hidden;
            position: absolute;
            top: 8px;
            left: 8px;
            > svg {
              display: block;
              width: 24px;
              height: 48px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
				&:hover {
          background: $gray-200;
          > span {
            overflow: hidden;
            &:nth-child(1) {
              > svg {
                top: -24px;
                path {
                  fill: $gray-600;
                }
              }
            }
            &:nth-child(2) {
              color: $gray-600;
            }
          }
				}
			}
			&.active {
        a {
          background-color: $gray-200;
          color: $black;
          > span {
            overflow: hidden;
            &:nth-child(1) {
              > svg {
                top: -24px;
                path {
                  fill: $primary;
                }
              }
            }
            &:nth-child(2) {
              color: $black;
            }
          }
        }
			}
		}
	}
}
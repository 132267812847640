@import "../../assets/scss/variables";

.modal {
  position: fixed;
  z-index: 2050;
  width: 100%;
  height: 100vh;
  background: rgba($black, 0.5);
  top: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  
  .modal__content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;

    > .vertical-spacer {
      display: inline-block;
      vertical-align: middle;
      height: 100vh;
      width: 0;
    }

    > div {
      margin: 16px 0;
      display: inline-block;
      vertical-align: middle;
      text-align: initial;
    }
  }
}

@import './variables.scss';

.wrapper_select_single {
  width: 100%;
  min-height: 36px;
  margin: 0;
  padding: 0;

  .react-select_single__control {
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: 36px;
    border: 1px solid $gray-400 !important;
    background: $white;
    border-radius: 4px;
    position: relative;

    > .react-select_single__value-container {
      flex-wrap: wrap;
      padding: 2px 8px;
      overflow: hidden;

      .react-select_single__input-container {
        flex: 1 1 auto;
        padding: 0 2px;
        min-height: 20px;
        margin: 5px 0;
      }
      .react-select_single__placeholder {
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgba($black, .5);
        padding: 0;
        width: auto;
        margin: 2px;
      }
      .react-select_single__multi-value,
      .react-select_single__value {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        width: auto;
        position: relative;
        padding: 0 24px 0 4px;
        background: $shadow-table-expanded;
        margin: 2px;
        z-index: 2;
        > .react-select_single__multi-value__label,
        > .react-select_single__value__label {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $black;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;
          margin: 0;
        }
        > .react-select_single__multi-value__remove,
        > .react-select_single__value__remove {
          display: block;
          width: 20px;
          height: 20px;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          right: 0;
          background: transparent url('../svg/x.svg') no-repeat scroll 50% 50%;
          background-size: 12px 12px;
          cursor: pointer;
          > svg {
            display: none;
          }
          &:hover {
            opacity: 0.5 !important;
          }
        }
      }
    }

    > .react-select_single__indicators {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      width: auto;
      max-width: 65px;

      .loader-wrapper {
        margin: 0;
        padding: 0;
        order: 1;
        flex: 0 1 auto;
        align-self: auto;
      }

      .react-select_single__clear-indicator {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        > svg {
          display: none !important;
        }
        background: transparent url('../svg/toggle-clear.svg') no-repeat scroll 0 0;
        order: 1;
        flex: 0 1 auto;
        align-self: auto;
      }
  
      .react-select_single__indicator-separator {
        width: 17px;
        height: 20px;
        padding: 0;
        margin: 0;
        background: none;
        position: relative;
        display: block;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          margin: 0;
          padding: 0;
          top: 0;
          left: 8px;
          background: $gray-600;
        }
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
      }

      .react-select_single__dropdown-indicator {
        position: relative;
        width: 32px;
        height: 32px;
        margin: 0;
        padding: 0;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          margin: -8px 0 0 -8px;
          padding: 0;
          top: 50%;
          left: 50%;
          background: transparent url('../svg/toggle-expand.svg') no-repeat scroll 0 0;
        }
        > svg {
          display: none;
        }
        order: 2;
        flex: 0 1 auto;
        align-self: auto;
        cursor: pointer;
      }
    }

    &--is-focused {
      border: 1px solid $black !important;
      box-shadow: 0px 0px 0px 1px $black;

      > .react-select_single__indicators {
        .react-select_single__clear-indicator {
          background-position: 0 -16px;
        }

        .react-select_single__indicator-separator {
          &::before {
            background: $black;
          }
        }
      }
    }

    &--menu-is-open {
      > .react-select_single__indicators {
        .react-select_single__dropdown-indicator {
          &::before {
            background-position: 0 -16px;
          }
        }
      }
    }

  }

  &.react-select_single--is-disabled {
    > .react-select_single__control {
      background: $gray-100;
      border: 1px solid $gray-400 !important
    }
  }

  &.error {
    .react-select_single__control {
      border-color: $red-600 !important;

      &.react-select_single__control--is-focused {
        border-color: $red-600 !important;
        box-shadow: 0px 0px 0px 1px $red-600 !important;
      }
    }
  }

}

.react-select_single__menu {
  background: $white;
  border: 1px solid $gray-200;
  box-shadow: 0px 6px 24px -6px rgba($black, .2);
  border-radius: 4px;
  margin: 0;
  padding: 0;
  z-index: 100 !important;
  
  .react-select_single__menu-list {
    padding: 0;
    margin: 0;
    .react-select_single__option {
      padding: 8px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      color: $black;
      position: relative;
      cursor: pointer;
      min-height: 36px;
    }
    .react-select_single__option--is-focused {
      background-color: transparent;
    }
    .react-select_single__option:hover {
      background-color: $gray-100;
    }
    .react-select_single__option--is-selected {
      background-color: $gray-100;
      color: $black;
    }
    .react-select_single__group {
      padding: 0;
      margin: 0;
      .react-select_single__group-heading {
        padding: 8px 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        text-transform: none;
        margin: 0;
        white-space: normal;
        word-break: break-all;
        border-bottom: 1px solid $gray-200;
      }
      .react-select_single__group-heading + div {
        .react-select_single__option {
          padding-left: 32px;
        }
      }
    }
  }

  .flat-virtualized-item {
    height: 36px !important;
    .fast-option {
      padding: 0 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 36px !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $black;
      position: relative;
      cursor: pointer;
    } 
    .fast-option.fast-option-focused {
      background-color: $gray-100;
    } 
    .fast-option.fast-option-selected {
      background-color: $gray-100;
      color: $black;
    }
  }
}

.wrapper_select_single.wrapper_select_inline {
  display: inline-block;
  vertical-align: top;
  max-width: 120px;
  margin: 0;
}

.wrapper_select_multiple {
  width: 100%;
  min-height: 36px;
  margin: 0;
  padding: 0;

  .react-select_multiple__control {
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: 36px;
    border: 1px solid $gray-400 !important;
    background: $white;
    border-radius: 4px;
    position: relative;

    > .react-select_multiple__value-container {
        flex-wrap: wrap;
        padding: 2px 8px;
        overflow: hidden;
      .react-select_multiple__input-container {
        flex: 1 1 auto;
        padding: 0 2px;
        min-height: 20px;
        margin: 5px 0;
      }
      .react-select_multiple__placeholder {
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgba($black, .5);
        padding: 0;
        width: auto;
        margin: 2px;
      }
      .react-select_multiple__multi-value {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        width: auto;
        position: relative;
        padding: 0 24px 0 4px;
        background: $shadow-table-expanded;
        margin: 2px;
        z-index: 2;
        > .react-select_multiple__multi-value__label {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $black;
          white-space: normal;
          width: 100%;
          overflow: hidden;
          word-break: break-all;
          padding: 0;
          margin: 0;
        }
        > .react-select_multiple__multi-value__remove {
          display: block;
          width: 20px;
          height: 20px;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          right: 0;
          background: transparent url('../svg/x.svg') no-repeat scroll 50% 50%;
          background-size: 12px 12px;
          cursor: pointer;
          > svg {
            display: none;
          }
          &:hover {
            opacity: 0.5 !important;
          }
        }
        > .react-select_multiple__multi-value__label.isDisabled + .react-select_multiple__multi-value__remove {
          opacity: 0.25 !important;
          pointer-events: none !important;
          cursor: not-allowed !important;
        }
        &.error {
          background: $red-600 !important;
          > .react-select_multiple__multi-value__label {
            color: $white !important;
          }
          > .react-select_multiple__multi-value__remove {
            background-image: url('../svg/x-white.svg') !important;
          }
        }
      }
    }

    > .react-select_multiple__indicators {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      width: auto;
      max-width: 65px;

      .loader-wrapper {
        margin: 0;
        padding: 0;
        order: 1;
        flex: 0 1 auto;
        align-self: auto;
      }

      .react-select_multiple__clear-indicator {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        > svg {
          display: none !important;
        }
        background: transparent url('../svg/toggle-clear.svg') no-repeat scroll 0 0;
        order: 1;
        flex: 0 1 auto;
        align-self: auto;
      }
  
      .react-select_multiple__indicator-separator {
        width: 17px;
        height: 20px;
        padding: 0;
        margin: 0;
        background: none;
        position: relative;
        display: block;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          margin: 0;
          padding: 0;
          top: 0;
          left: 8px;
          background: $gray-600;
        }
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
      }

      .react-select_multiple__dropdown-indicator {
        position: relative;
        width: 32px;
        height: 32px;
        margin: 0;
        padding: 0;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          margin: -8px 0 0 -8px;
          padding: 0;
          top: 50%;
          left: 50%;
          background: transparent url('../svg/toggle-expand.svg') no-repeat scroll 0 0;
        }
        > svg {
          display: none;
        }
        order: 2;
        flex: 0 1 auto;
        align-self: auto;
        cursor: pointer;
      }
    }

    &--is-focused {
      border: 1px solid $black !important;
      box-shadow: 0px 0px 0px 1px $black;

      > .react-select_multiple__indicators {
        .react-select_multiple__clear-indicator {
          background-position: 0 -16px;
        }

        .react-select_multiple__indicator-separator {
          &::before {
            background: $black;
          }
        }
      }
    }

    &--menu-is-open {
      > .react-select_multiple__indicators {
        .react-select_multiple__dropdown-indicator {
          &::before {
            background-position: 0 -16px;
          }
        }
      }
    }

  }

  &.react-select_multiple--is-disabled {
    > .react-select_multiple__control {
      background: $gray-100;
      border: 1px solid $gray-400 !important
    }
  }

  &.error {
    .react-select_multiple__control {
      border-color: $red-600 !important;

      &.react-select_multiple__control--is-focused {
        border-color: $red-600 !important;
        box-shadow: 0px 0px 0px 1px $red-600 !important;
      }
    }
  }

}

.react-select_multiple__menu {
  background: $white;
  border: 1px solid $gray-200;
  box-shadow: 0px 6px 24px -6px rgba($black, .2);
  border-radius: 4px;
  margin: 0;
  padding: 0;
  z-index: 100 !important;
  
  .react-select_multiple__menu-list {
    padding: 0;
    margin: 0;
    .react-select_multiple__option {
      padding: 8px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      color: $black;
      position: relative;
      min-height: 36px;
      .wrapper-checkbox {
        padding: 2px 0 2px 24px;
        margin: 0;
        text-transform: none;
      }
    }
    .react-select_multiple__option--is-focused {
      background-color: transparent;
    }
    .react-select_multiple__option:hover {
      background-color: $gray-100;
    }
    .react-select_multiple__option--is-selected {
      background-color: $gray-100;
      color: $black;
    }

    .react-select_multiple__group {
      margin: 0;
      padding: 0;
    }

    .react-select_multiple__group-heading {
      padding: 8px 16px 0;
      margin: 0;
      .wrapper-checkbox {
        padding: 8px 0;
        border-bottom: 1px solid $gray-200;
        margin: 0;
        text-transform: none;
        > label {
          > span {
            white-space: normal;
            word-break: break-all;
          }
        }
      }
      &.active,
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}

.react-select_multiple__menu-portal,
.react-select_single__menu-portal {
  z-index: 1060 !important;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ref-select {
  position: relative;
  z-index: 1060 !important;
}

// --- 

.table_multiple_filter {
  .react-select_multiple__control {
    > .react-select_multiple__value-container {
      .react-select_multiple__multi-value {
        > .react-select_multiple__multi-value__label {
          white-space: nowrap;
        }
        & ~ input {
          height: 0;
        }
      }
    }
  }
  
  .react-select_multiple__menu-portal {
    .wrapper-checkbox {
      padding: 0 !important;
    }
  }
}
@import './variables.scss';

::-webkit-scrollbar-corner {
  background: rgba($white, 0);
}
::-webkit-scrollbar {
  width: 8px;
  background-color: rgba($white, 0);
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-track {
  border: 0 none;
  border-radius: 4px;
  box-shadow: none;
}
::-webkit-scrollbar-thumb {
  background-color: $gray-400;
  background-clip: content-box;
  border: 1px solid transparent;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $gray-400;
}
::-webkit-scrollbar-thumb:active {
  background-color: $gray-400;
}

textarea::-webkit-resizer {
  background-image: url('../svg/resizer/normal.svg');
  background-size: 8px 8px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

p.pre::-webkit-resizer {
  background-image: url('../svg/resizer/normal.svg');
  background-size: 8px 8px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

textarea:active::-webkit-resizer,
textarea:focus::-webkit-resizer {
  background-image: url('../svg/resizer/focus.svg');
  background-size: 8px 8px;
  background-repeat: no-repeat;
  background-position: 100% 100%
}
textarea.error::-webkit-resizer {
  background-image: url('../svg/resizer/error.svg') !important;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  background-position: 100% 100%
}

* {
  scrollbar-color: $gray-400 rgba($white, 0);
  scrollbar-width: thin;
}

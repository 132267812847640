@import '../../assets/scss/variables.scss';

.tooltip {
  z-index: 1070;
  width: fit-content;
  display: inline-block;
  background: $white;
  margin: 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $shadow-table-expanded;
  box-shadow: 0px 0 20px 0 rgba($black, 0.2);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $black;
  word-break: break-all;
  white-space: pre-line;
  &::before {
    content: '';
    display: block;
    position: absolute;
    background: transparent;
    width: 10px;
    height: 10px;
  }
  &-wrapper {
    position: relative;
    display: flex;
    > .line-clamp:last-child {
      width: 100%;
      max-width: 100%;
    }
  }
  &-content {
    overflow: auto;
    padding: 0;
    max-height: 100px;
    margin: 0;
    .xml-pre {
      white-space: pre;
    }
    .xml-pre-wrap {
      white-space: pre-wrap;
    }
  }
  .hr {
    margin: 10px 0 0;
    padding: 10px 0 0;
    border-top: 1px solid $shadow-table-expanded;
  }
  &-button {
    border: none !important;
    &.copy {
      width: 100%;
      margin: 0 auto;
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
    &.show {
      padding: 0;
      margin-left: 8px;
      background-color: transparent;
      max-width: 20px !important;
      width: 20px !important;
      min-width: 20px !important;
      > span {
        &::before {
          top: 50%;
          left: 50% !important;
          margin: -8px 0 0 -8px !important;
        }
      }
      &:hover {
        background-color: transparent !important;
        opacity: 0.5;
      }
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
  &-top {
    margin: -10px 10px 0;
    &::before {
      left: calc(50% - 10px);
      bottom: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
    }
  }
  &-bottom {
    margin: 10px 10px 0;
    &::before {
      left: calc(50% - 10px);
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
  }
  &-left {
    margin: 10px;
    &::before {
      top: calc(50% - 10px);
      right: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
    }
  }
  &-right {
    margin: 10px;
    &::before {
      top: calc(50% - 10px);
      left: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }
  }
}

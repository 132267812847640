@import "./variables.scss";

.wrapper.wrapper-page {
  &.import-export-settings-page {
    h1 + .tab {
      margin-top: 16px;
    }

    .tab {
      .tab__panel {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;

        > div {
          flex: 1 1 auto;
          align-self: stretch;

          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-content: stretch;
          align-items: stretch;

          .table-loader,
          .table-loader .table,
          .table-loader .table .wrapper-sticky-pagination {
            align-content: stretch;
            align-items: stretch;
            align-self: auto;
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            order: 0;
            .table-responsive {
              align-self: auto;
              flex: 1 1 auto;
              order: 0;

              // ---

              .checkbox-column {
                width: 40px !important;
                min-width: 40px !important;
                max-width: 40px !important;
              }

              .details-column {
                flex: 1 1 auto !important;
                min-width: 360px !important;
              }

              // ---

              thead {
                z-index: 5 !important;
                tr {
                  th.expanded-column {
                    display: none !important;
                  }

                  .parameters-column {
                    width: 360px !important;
                    max-width: 360px !important;
                    min-width: 360px !important;
                  }
                }
              }
    
              tbody {
                tr {
                  height: auto;
                }
    
                tr[data-depth="0"][data-expand="false"] {
                  td.expanded-column {
                    display: none !important;
                  }
                }
    
                tr {
                  .expanded-column {
                    flex: 0 0 auto !important;
                    width: auto !important;
                    max-width: none !important;
                    min-width: 40px !important;
                    padding: 0 !important;
                    background-image: url("../svg/table-dot.svg") !important;
                    background-position: 0 0 !important;
                    background-repeat: repeat-x !important;
      
                    .toggle-expand {
                      display: block;
                      padding: 0;
                      margin: 0;
                      width: auto;
                      min-width: 40px;
                      height: 44px;
                      background: none;
                      text-align: right;
                      &::before {
                        content: "";
                        display: inline-block;
                        vertical-align: top;
                        margin: 0;
                        padding: 0;
                        width: 40px;
                        height: 44px;
                        background: $white url("../svg/toggle-expand.svg") no-repeat scroll 0 -32px;
                        background-size: 16px 48px;
                        border-width: 14px 12px;
                        border-style: solid;
                        border-color: $white;
                      }
                      &.expanded {
                        &::before {
                          background-position: 0 0;
                        }
                      }
                    }
                  }

                  &:hover {
                    .expanded-column {
                      .toggle-expand {
                        &::before {
                          content: "";
                          background-color: $gray-100 !important;
                          border-color: $gray-100 !important;
                        }
                       }
                    }
                  }
                }

                tr[data-removed="true"] {
                  background: $red-200;
                  .expanded-column {
                    .toggle-expand {
                      &::before {
                        background-color: $red-200 !important;
                        border-color: $red-200 !important;
                      }
                    }
                  }

                  &:hover {
                    background: $red-200-hover;
                    .expanded-column {
                      .toggle-expand {
                        &::before {
                          background-color: $red-200-hover !important;
                          border-color: $red-200-hover !important;
                        }
                      }
                    }
                  }
                }   
                
                tr[data-added="true"] {
                  background: $green-200;
                  .expanded-column {
                    .toggle-expand {
                      &::before {
                        background-color: $green-200 !important;
                        border-color: $green-200 !important;
                      }
                    }
                  }

                  &:hover {
                    background: $green-200-hover;
                    .expanded-column {
                      .toggle-expand {
                        &::before {
                          background-color: $green-200-hover !important;
                          border-color: $green-200-hover !important;
                        }
                      }
                    }
                  }
                } 
                
                tr[data-changed="true"] {
                  background: $yellow-200;
                  .expanded-column {
                    .toggle-expand {
                      &::before {
                        background-color: $yellow-200 !important;
                        border-color: $yellow-200 !important;
                      }
                    }
                  }

                  &:hover {
                    background: $yellow-200-hover;
                    .expanded-column {
                      .toggle-expand {
                        &::before {
                          background-color: $yellow-200-hover !important;
                          border-color: $yellow-200-hover !important;
                        }
                      }
                    }
                  }
                }   

                tr[data-selected="true"] {
                  background: $active-table-selected !important;
                  .expanded-column {
                    .toggle-expand {
                      &::before {
                        background-color: $active-table-selected !important;
                        border-color: $active-table-selected !important;
                      }
                    }
                  }

                  &:hover {
                    background: $selected-hover !important;
                    .expanded-column {
                      .toggle-expand {
                        &::before {
                          background-color: $selected-hover !important;
                          border-color: $selected-hover !important;
                        }
                      }
                    }
                  }
                }
         
                &:not([data-depth="0"]) {
                  tr:not(.no-border) {
                    border-bottom: 0 none;
                    background: $gray-100;
                    td {
                      background: $gray-100;
                    }

                    .toggle-expand {
                      &::before {
                        background-color: $gray-100;
                        border-color: $gray-100;
                      }
                    }

                    &:hover {
                      background: $gray-100-hover;
                      td {
                        background: $gray-100-hover;
                      }

                      .toggle-expand {
                        &::before {
                          background-color: $gray-100-hover !important;
                          border-color: $gray-100-hover !important;
                        }
                      }
                    }

                    &[data-removed="true"] {
                      background: $red-200 !important;
                      td {
                        background-color: $red-200 !important;
                      }

                      &:hover {
                        background: $red-200-hover !important;
                        td {
                          background-color: $red-200-hover !important;
                        }
                        
                        .expanded-column {
                          .toggle-expand {
                            &::before {
                              background-color: $red-200-hover !important;
                              border-color: $red-200-hover !important;
                            }
                          }
                        }
                      }
                    }  

                    &[data-added="true"] {
                      background: $green-200 !important;
                      td {
                        background-color: $green-200 !important;
                      }

                      &:hover {
                        background: $green-200-hover !important;
                        td {
                          background-color: $green-200-hover !important;
                        }
                        
                        .expanded-column {
                          .toggle-expand {
                            &::before {
                              background-color: $green-200-hover !important;
                              border-color: $green-200-hover !important;
                            }
                          }
                        }
                      }
                    } 

                    &[data-changed="true"] {
                      background: $yellow-200 !important;
                      td {
                        background-color: $yellow-200 !important;
                      }

                      &:hover {
                        background: $yellow-200-hover !important;
                        td {
                          background-color: $yellow-200-hover !important;
                        }
                        
                        .expanded-column {
                          .toggle-expand {
                            &::before {
                              background-color: $yellow-200-hover !important;
                              border-color: $yellow-200-hover !important;
                            }
                          }
                        }
                      }
                    }

                    &[data-selected="true"] {
                      background: $active-table-selected !important;
                      td {
                        background-color: $active-table-selected !important;
                      }

                      &:hover {
                        background: $selected-hover !important;
                        td {
                          background-color: $selected-hover !important;
                        }
                        
                        .expanded-column {
                          .toggle-expand {
                            &::before {
                              background-color: $selected-hover !important;
                              border-color: $selected-hover !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &[data-depth="0"] + [data-depth="1"] {
                  tr:first-child {
                    td {
                      &::before {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 5px;
                        background: linear-gradient(to bottom, $shadow-table-expanded 0%, transparent 100%);
                        position: absolute;
                        top: 0;
                        left: 0;
                      }
                    }

                    &[data-removed="true"] {
                      td {
                        &::before {
                          background: linear-gradient(to bottom, $red-200-hover 0%, transparent 100%);
                        }
                      }
                    }

                    &[data-added="true"] {
                      td {
                        &::before {
                          background: linear-gradient(to bottom, $green-200-hover 0%, transparent 100%);
                        }
                      }
                    }

                    &[data-changed="true"] {
                      td {
                        &::before {
                          background: linear-gradient(to bottom, $yellow-200-hover 0%, transparent 100%);
                        }
                      }
                    }

                    &[data-selected="true"] {
                      td {
                        &::before {
                          background: linear-gradient(to bottom, $selected-hover 0%, transparent 100%);
                        }
                      }
                    }
                  }
                }

                &[data-depth="0"] {
                  .expanded-column {
                    width: 40px !important;
                    min-width: 40px !important;
                    max-width: 40px !important;
                  }

                  .parameters-column {
                    width: 320px !important;
                    max-width: 320px !important;
                    min-width: 320px !important;
                  }
                }

                &[data-depth="1"] {
                  .expanded-column {
                    width: 80px !important;
                    min-width: 80px !important;
                    max-width: 80px !important;
                  }

                  .parameters-column {
                    width: 280px !important;
                    max-width: 280px !important;
                    min-width: 280px !important;
                  }
                }

                &[data-depth="2"] {
                  .expanded-column {
                    width: 120px !important;
                    min-width: 120px !important;
                    max-width: 120px !important;
                  }

                  .parameters-column {
                    width: 240px !important;
                    max-width: 240px !important;
                    min-width: 240px !important;
                  }
                }
              }
    
              // ---
            }
          }
        }
      }
    }

    .export-tab {
      .wrapper-alert-inline {
        &:empty {
          margin: 0;
          display: none;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        & + .wrapper-buttons {
          margin-top: 0;
        }
      }

      .wrapper-buttons {
        margin: 16px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .btn {
          display: inline-block;
          width: auto;
          max-width: 100%;
          margin: 0;
        }
      }
    }

    .import-tab {
      
      .wrapper-alert-inline {
        &:empty {
          margin: 0;
          display: none;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        & + .flex-buttons,
        & + .wrapper-form {
          margin-top: 0;
        }
      }

      .wrapper-form {
        margin: 16px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .wrapper-file-upload {
          .file {
            margin: 0;
          }
          .error,
          p {
            margin: 4px 0 0;
          }
        }

        .wrapper-buttons {
          .btn.upload {
            > span {
              &::before {
                background: transparent url("../svg/arrowUp.svg") no-repeat scroll 0 -16px;
              }
            }
            &:disabled {
              background-color: $btn-disabled;
              box-shadow: none;
              color: $white;
              cursor: not-allowed !important;
              pointer-events: none !important;
              opacity: 1;
            }
          }
        }
      }

      .flex-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        margin: 16px -8px;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        > div {
          flex: 0 1 auto;
          align-self: auto;
          width: auto;
          max-width: 50%;
          padding: 0 8px;
          margin: 0;
        }
      }
    }
  }
}
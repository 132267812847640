.wrapper-input.wrapper-input-filter {
  position: relative;
  margin: 8px 0 0;
  input {
    padding-right: 32px !important;
    margin-bottom: 0 !important;
  }
  input + .clear-indicator {
    display: block;
    margin: -8px 0 0;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: transparent url('../../../assets/svg/toggle-clear.svg') no-repeat scroll 0 0;
  }
  input:focus + .clear-indicator {
    background-position: 0 -16px;
  }
  input[data-empty="true"] {
    padding-right: 8px !important;
  }
}
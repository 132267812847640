@import "../../assets/scss/variables.scss";

.table {
  table {
    border-collapse: collapse;
    caption-side: bottom;
    min-width: 100% !important;
  }

  th,
  td {
    padding: 12px 16px;
    white-space: normal;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;

    .wrapper-cell {
      width: 100%;
      max-width: 100%;
      white-space: normal;
      .line-clamp {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .new-line {
          margin: 0 0 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:last-child {
            margin: 0;
          }
        }
      }
      .actions-btn {
        :first-child {
          margin: 0 8px 0 2px;
        }
        .btn {
          &:active,
          &:focus-visible {
            border: 1px solid $gray-200;
            box-shadow: none;
            background-color: $white;
            outline: none;
          }
        }
      }
    }
  }

  td {
    .wrapper-cell {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $black;

      span.error {
        color: $red-600;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        width: calc(100% - 18px);
        // margin: 4px 9px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  tbody {
    position: relative;
    tr {
      border-bottom: 1px solid $gray-200;
      &.no-border {
        border: 0 none;
      }
      &[data-selected="true"] {
        background: $active-table-selected;
      }
      &[data-disabled="true"] {
        background: $gray-100;

        td[data-sticky-td] {
          background: $gray-100 !important;
        }
      }
      &[data-clickable="true"] {
        cursor: pointer;
      }
      &[data-error-row="true"] {
        background: $red-200;

        td[data-sticky-td] {
          background: $red-200 !important;
        }
      }
      &[data-warning-row="true"] {
        background: $yellow-200;
      }
    }
  }

  .wrapper-checkbox.without-text {
    width: 20px;
    height: 20px;
    position: relative;
    display: block;
    padding: 0;
    margin: 0 auto;
    label {
      width: 20px;
      padding: 0;
      margin: 0;
      display: block;
      height: 20px;
      overflow: hidden;
      input {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        max-width: 20px;
        max-height: 20px;
      }
      span {
        display: block;
        position: relative;
        z-index: 1;
        width: 20px;
        height: 20px;
        &::before {
          top: 50%;
          left: 50%;
          margin: -8px 0 0 -8px;
        }
      }
      input:disabled + span {
        opacity: 1 !important;
        &::before {
          background-color: $gray-200;
          opacity: 1 !important;
          box-shadow: none !important;
          border-color: $gray-400 !important;
        }
      }
    }
  }

  .wrapper-radio-box.without-text {
    width: 20px;
    height: 20px;
    position: relative;
    display: block;
    padding: 0;
    margin: 0 auto;
    label {
      width: 20px;
      padding: 0;
      margin: 0;
      display: block;
      height: 20px;
      overflow: hidden;
      input {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        max-width: 20px;
        max-height: 20px;
      }
      span {
        display: block;
        position: relative;
        z-index: 1;
        width: 20px;
        height: 20px;
        &::before {
          top: 2px;
          left: 2px;
        }
      }
      input:disabled + span {
        opacity: 1 !important;
        &::before {
          background-color: $gray-200;
          opacity: 1 !important;
          box-shadow: none !important;
        }
      }
    }
  }

  thead {
    background: $gray-100;
    th {
      color: $gray-600;
      font-weight: $bold;
      border-bottom: 2px solid $gray-200;
      .wrapper-checkbox {
        label {
          input:disabled + span {
            &::before {
              opacity: 0.25;
            }
          }
        }
      }
    }
    .react-datepicker-wrapper {
      .wrapper-input.wrapper-input-filter {
        margin: 0;
      }
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    tbody td[data-sticky-td] {
      background-clip: padding-box !important;
      border-width: 1px 0;
      border-style: solid;
      border-color: transparent;
      transform: translate3d(0, 0, 0) !important;
      zoom: 1;
    }

    &[data-scroll-start="true"] {
      tbody tr[data-selected="true"] td[data-sticky-td] {
        background: $active-table-selected;
      }

      thead th[data-sticky-td] {
        background: $gray-100;
      }

      tbody td[data-sticky-td] {
        background: $white;
        &:hover {
          z-index: 5 !important;
        }
      }

      thead th[data-sticky-last-left-td] {
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 100%;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          left: 100%;
          background: linear-gradient(90deg, rgba($black, 0.1) 0, transparent 100%);
        }
      }

      tbody td[data-sticky-last-left-td] {
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 100%;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          left: 100%;
          background: linear-gradient(90deg, rgba($black, 0.1) 0, transparent 100%);
        }
      }
    }
    &[data-scroll-end="true"] {
      tbody tr[data-selected="true"] td[data-sticky-td] {
        background: $active-table-selected;
      }

      thead th[data-sticky-td] {
        background: $gray-100;
      }

      tbody td[data-sticky-td] {
        background: $white;
        &:hover {
          z-index: 5 !important;
        }
      }

      thead th[data-sticky-first-right-td] {
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 100%;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          right: 100%;
          background: linear-gradient(90deg, transparent 0, rgba($black, 0.1) 100%);
        }
      }

      tbody td[data-sticky-first-right-td] {
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 100%;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          right: 100%;
          background: linear-gradient(90deg, transparent 0, rgba($black, 0.1) 100%);
        }
      }
    }

    table {
      thead {
        tr ~ tr {
          border-bottom: 1px solid $gray-200;
          td {
            background: $white;
            &.editable {
              .wrapper-cell,
              .line-clamp {
                overflow: unset;
              }
            }
          }
          &[data-error-row="true"] {
            td {
              background: $red-200;
            }
          }
        }
      }

      tbody {
        tr.edited {
          td.editable {
            .wrapper-cell,
            .line-clamp {
              overflow: unset;
            }
          }
        }
      }
    }
  }

  .name-column {
    width: 40% !important;
    max-width: 40% !important;
    min-width: 40% !important;
  }

  .checkbox-column,
  .radio-column {
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .expanded-column {
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    .toggle-expand {
      cursor: pointer;
      display: block;
      margin: 0 auto;
      width: 16px;
      height: 16px;
      padding: 0;
      background: transparent url("../../assets/svg/toggle-expand.svg") no-repeat scroll 0 -32px;
      background-size: 16px 48px;
      &.expanded {
        background-position: 0 0;
      }
      &.fetching {
        opacity: 0.6;
      }
    }
  }

  .wrapper_select_single,
  .wrapper_select_multiple,
  .wrapper-input {
    margin: 8px 0 0;
  }

  .wrapper-tag-input {
    .wrapper_select_multiple
      .react-select_multiple__control
      > .react-select_multiple__indicators
      .react-select_multiple__clear-indicator {
      margin: 0 8px 0 0;
    }
  }

  .sub-row-logs {
    padding-left: 68px;
    background: $gray-100;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 10px;
      background: linear-gradient(to bottom, $shadow-table-expanded 0%, transparent 100%);
      position: absolute;
      top: 0;
      left: 0;
    }
    .wrapper-cell {
      white-space: normal;
      word-break: break-all;
    }
  }

  .sub-row-sessions {
    border-bottom: 0 none;
    td {
      background: $gray-100;
    }

    td[data-sticky-td] {
      background: $gray-100 !important;
    }

    &.first {
      td {
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 10px;
          background: linear-gradient(to bottom, $shadow-table-expanded 0%, transparent 100%);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .status-icon {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 100%;
    min-height: 20px;
    position: relative;
    padding: 0 0 0 24px;
    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 2px;
      left: 0;
    }
    &.valid,
    &.ok {
      &::before {
        background: transparent url("../../assets/svg/alert_error_success.svg");
      }
    }
    &.invalid,
    &.error {
      &::before {
        background: transparent url("../../assets/svg/alert_error_icon.svg");
      }
      color: $black !important;
    }
    &.pending {
      &::before {
        background: transparent url("../../assets/svg/notifications/started.svg");
      }
    }
    &.warning {
      &::before {
        background: transparent url("../../assets/svg/alert_warning_ico.svg");
      }
    }
    &.undefined {
      &::before {
        background: transparent url("../../assets/svg/api-user.svg") no-repeat scroll 0 -16px;
      }
    }
  }

  .no-data {
    margin: 24px auto;
    max-width: 180px;
    > span {
      display: block;
      width: 128px;
      height: 128px;
      margin: 0 auto;
      background: transparent url("../../assets/svg/no-data.svg") no-repeat scroll 50% 50%;
      background-size: contain;
    }
    > p {
      display: block;
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $black;
      color: rgba($black, 0.5);
      text-align: center;
    }
  }

  .btn.link.action {
    margin: 0 auto;
    box-shadow: none;
    > span {
      min-height: 20px;
      padding: 0 0 0 16px;
      width: 16px;
    }
  }

  .api-icon {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 100%;
    min-height: 16px;
    position: relative;
    padding: 0 0 0 24px;
    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.yes {
      &::before {
        background: transparent url("../../assets/svg/api-user.svg") no-repeat scroll 0 0;
      }
    }
    &.no {
      &::before {
        background: transparent url("../../assets/svg/api-user.svg") no-repeat scroll 0 -16px;
      }
    }
    &.arrow-up {
      &::before {
        background: transparent url("../../assets/svg/tbl-arrow-up.svg") no-repeat scroll 0 4px;
      }
    }
    &.arrow-down {
      &::before {
        background: transparent url("../../assets/svg/tbl-arrow-down.svg") no-repeat scroll 0 4px;
      }
    }
    &.not-started {
      &::before {
        background: transparent url("../../assets/svg/clock.svg") no-repeat scroll 0 0;
      }
    }
    &.loading-icon {
      overflow: hidden;
      &::before {
        content: none;
        display: none;
      }
      & > .loader-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    &.error-icon {
      color: $red-600;
      &::before {
        background: transparent url("../../assets/svg/alert_error_icon.svg") no-repeat scroll 0 0px;
      }
    }
    &.skipped-icon {
      &::before {
        background: transparent url("../../assets/svg/skipped.svg") no-repeat scroll 0 0px;
      }
    }
    // new 
    &.down {
      &::before {
        background: transparent url("../../assets/svg/alert_error_icon.svg") no-repeat scroll 0 0px;
      }
    }
    &.leaving {
      &::before {
        background: transparent url("../../assets/svg/leaving.svg") no-repeat scroll 0 0px;
      }
    }
    &.joining {
      &::before {
        background: transparent url("../../assets/svg/joining.svg") no-repeat scroll 0 0px;
      }
    }
    &.moving {
      &::before {
        background: transparent url("../../assets/svg/moving.svg") no-repeat scroll 0 0px;
      }
    }
    &.fail {
      &::before {
        background: transparent url("../../assets/svg/notifications/failed.svg") no-repeat scroll 0 0px;
      }
    }
    &.skipped {
      &::before {
        background: transparent url("../../assets/svg/skipped-upgrade.svg") no-repeat scroll 0 0px;
      }
    }
  }

  .text-right {
    .wrapper-cell {
      .line-clamp {
        text-align: right;
      }
    }
  }

  .color-grey {
    color: $gray-600;
  }

  .api-icon-after {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 100%;
    min-height: 16px;
    position: relative;
    padding: 0 24px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
    &.yes {
      &::after {
        background: transparent url("../../assets/svg/api-user.svg") no-repeat scroll 0 0;
      }
    }
    &.no {
      &::after {
        background: transparent url("../../assets/svg/api-user.svg") no-repeat scroll 0 -16px;
      }
    }
    &.arrow-up {
      &::after {
        background: transparent url("../../assets/svg/tbl-arrow-up.svg") no-repeat scroll 0 5px;
      }
    }
    &.arrow-down {
      &::after {
        background: transparent url("../../assets/svg/tbl-arrow-down.svg") no-repeat scroll 0 5px;
      }
    }
    &.warning-icon {
      &::after {
        background: transparent url("../../assets/svg/alert_warning_ico.svg") no-repeat scroll 0 0px;
      }
    }
    &.error-icon {
      color: $red-600;
      &::after {
        background: transparent url("../../assets/svg/alert_error_icon.svg") no-repeat scroll 0 0px;
      }
    }
  }
}

.can-sort {
  min-height: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $gray-600;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 24px 0 0;
  position: relative;
}

.default-arrow-table {
  width: 16px;
  height: 16px;
  margin: 0;
  display: block;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0;
  border: 0 none;
  outline: none !important;
  border-radius: 2px;
  background-color: transparent;

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    background: transparent url("../../assets/svg/default-arrow-table.svg") no-repeat scroll 50% 50%;
    background-size: contain;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px rgba($black, 0.2);
  }
}

.table-arrow-down {
  @extend .default-arrow-table;

  &::before {
    background: transparent url("../../assets/svg/arrow-table.svg") no-repeat scroll 50% 50%;
  }
}

.table-arrow-up {
  @extend .default-arrow-table;

  &::before {
    transform: rotate(180deg);
    background: transparent url("../../assets/svg/arrow-table.svg") no-repeat scroll 50% 50%;
  }
}

.wrapper-sticky-pagination {
  min-height: 136px;
  position: relative;
  .pagination {
    box-shadow: inset 0px 1px 0px $gray-200;
    width: 100%;
    padding: 8px 0;
    margin: 0;
    background: $white;
    margin-top: -1px;
    
    p {
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      margin: 0;
      padding: 0;
      white-space: normal;
      overflow: hidden;
      word-break: break-word;
    }

    .flex-box-pagination {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      width: 100%;
      gap: 10px 16px;

      > div {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        padding: 0;

        > .delimiter {
          width: 1px;
          height: 100%;
          min-height: 20px;
          display: block;
          background: $gray-200;
          margin: 0 auto;
          padding: 0;
        }

        &:nth-child(1) {
          width: auto;
          max-width: calc(50% - 16.5px);
        }

        &:nth-child(2) {
          width: auto;
          width: 1px;
          max-width: 1px;
          min-width: 1px;
          position: relative;
          align-self: stretch;
        }

        &:nth-child(3) {
          width: auto;
          max-width: calc(50% - 16.5px);

          .wrapper-per-page {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            gap: 8px;

            > div {
              order: 0;
              flex: 0 1 auto;
              align-self: auto;

              > .wrapper_select_single {
                width: 100%;
                max-width: 100%;
                min-width: 90px;
                margin: 0;
              }

              &:nth-child(2) {
                flex: 1 1 auto;
              }
            }
          }

        }

        &:nth-child(4) {
          width: auto;
          flex: 1 1 auto;

          .pagination-container {
            justify-content: flex-end;
          }
        }
      }

      &.isNotOneLine {
        justify-content: center;
        > div {
          &:nth-child(4) {
            .pagination-container {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.table_cell_button {
  border: none !important;
  background: transparent !important;
  font-weight: 400 !important;
  padding: 0 !important;
  width: 100% !important;
  display: block !important;
  text-align: left !important;
  &:active {
    box-shadow: none !important;
  }
  span {
    position: relative;
    display: block;
    padding-right: 18px;
    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: 0;
      margin: -8px 0 0;
      padding: 0;
      background: url("../../assets/svg/info.svg") no-repeat scroll 0 -16px;
    }
  }
}

p.text {
  margin: 0 0 4px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  width: 100%;
  color: $black;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 20px;
  &:last-child {
    margin: 0;
  }
}

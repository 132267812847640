@import "./variables";

.breadcrumbs {
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;

    &:after {
      content: "";
      background: transparent url("../svg/arrow-right.svg") no-repeat
        50% 50% / contain;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: -14px;
      transform: translate(0, -50%);
    }

    a {
      position: relative;
      display: block;
    }
  }
}

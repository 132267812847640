@import "./variables.scss";

.wrapper-version-page {
  width: 100%;
  min-height: 100vh;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;

  > div {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;
    width: 100%;
    max-width: 420px;
    padding: 16px;
    margin: 0;
    text-align: center;

    p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: normal;
      color: $black;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: initial;
    }

    .table {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      align-content: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      width: 100%;

      > div {
        -webkit-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        align-self: auto;
        width: 100%;
        border-bottom: 1px solid $gray-200;
      }

      .thead,
      .tbody {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        width: 100%;

        &.thead {
          background: $gray-100;
          border-bottom: 2px solid $gray-200;

          p {
            font-weight: 700;
            color: $gray-600;
          }
        }

        > div {
          -webkit-order: 0;
          order: 0;
          -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
          -webkit-align-self: auto;
          align-self: auto;
          padding: 12px 16px;
          margin: 0;
          width: 50%;
          max-width: 50%;
        }
      }
    }

    .wrapper-alert-inline {
      display: block;
    }

    .loader-wrap {
      position: static;
      top: auto;
      left: auto;
      transform: none;
      margin: 0 auto;
      width: 80px;

      .loader {
        margin: 0;
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.loader-wrapper {
  display: block;
  width: auto;
  max-width: 100%;
  margin: 16px 0;
  padding: 0;
  text-align: center;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin: 0;
    padding: 0;

    > div {
      transform-origin: 10px 10px;
      animation: lds-spinner 1.2s linear infinite;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0.75px;
        left: 9.25px;
        width: 1.5px;
        height: 4.5px;
        border-radius: 20%;
        background: $blue-600;
      }

      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }
      
      &:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }
      
      &:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }
      
      &:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }
      
      &:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }
      
      &:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }
      
      &:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }
      
      &:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }
      
      &:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }
      
      &:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }
      
      &:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }
      
      &:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    margin: 0 0 0 8px;
    padding: 0;
    width: auto;
    max-width: calc(100% - 28px);
    overflow: hidden;
  }

  &.double {
    .lds-spinner {
      width: 40px;
      height: 40px;
  
      > div {
        transform-origin: 20px 20px;
  
        &::after {
          top: 1.5px;
          left: 18.5px;
          width: 3;
          height: 9px;
        }
      }
    }

    span {
      margin: 0 0 0 16px;
      max-width: calc(100% - 56px);
    }
  }
  &.small-inline {
    display: inline-block;
    margin: 0;
    width: 20px;
    height: 20px;
    vertical-align: top;
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.documents-page {
  .document-info {
    height: 100%;

    h3 {
      display: block;
      width: 100%;
      font-size: 20px;
      line-height: 1.4;
      font-weight: 500;
      padding: 0;
      margin: 16px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table-loader.info-table {
    .table {
      .wrapper-sticky-pagination {
        min-height: auto;
        .table-responsive {
          height: auto !important;
        }
      }
    }
  }

  .wrapper-input {
    margin: 0;
    textarea {
      min-height: 88px;
    }
  }
}
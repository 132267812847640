.wrapper.wrapper-page {
  &.prompt {
    .breadcrumbs + .wrapper-alert-inline {
      margin-top: 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
